import ProductPageRenderStart from './product_page_render';
import GeneralRender from './general_render';

declare global {
  interface Window {
    initPlugin: (div_id: string, element_id: string) => void;
    renderThis: (category: string, custom_blocks?: any, pageData?: Object | undefined) => void;
    renderGeneral: (category: string, custom_blocks?: any, pageData?: Object | undefined) => void;

  }
}

let render_category = "";
const currentPath = window.location.pathname;
const pathSegments = currentPath.split('/').filter(segment => segment !== '');
const lastSegmentWithoutParams = pathSegments[pathSegments.length - 1]?.split('?')[0] || '';

const secondToLastSegment = pathSegments[pathSegments.length - 2]?.split('?')[0] || '';
const lastSegment = lastSegmentWithoutParams;



window.renderGeneral = (category: string, custom_blocks?: any, pageData?: Object) => {
  console.log("window.renderGeneral stopped");
  return;
  GeneralRender(render_category, custom_blocks, pageData || {});
};


function setupMutationObserver(page_category: string) {
  const observer = new MutationObserver((mutations, obs) => {
    const body = document.body;
    const FooterR = document.getElementById('footer_div');


    if (page_category === "products") {
      const topDiv = document.getElementById('top_div');

      if (body && topDiv) {
        console.log("observer start");
        ProductPageRenderStart(render_category, [], {});
        obs.disconnect(); // Stop observing once conditions are met
      }
    } else if (body && FooterR) {
      console.log("observer start");
      ProductPageRenderStart(page_category, [], {});
      obs.disconnect(); // Stop observing once conditions are met
    }
  });

  observer.observe(document, {
    childList: true,
    subtree: true
  });
}

if (secondToLastSegment === "products") {
  render_category = "products";
} else if (lastSegment === "collections" || lastSegment === "cart" || lastSegment === "support") {
  render_category = lastSegment;
} else if (secondToLastSegment === "info") {
  render_category = "info";
} else if (pathSegments.length === 0 || lastSegment === "") {
  render_category = "home";
}

console.log("render_category", render_category);
if (render_category) {
setupMutationObserver(render_category);
}

const static_blocks = [
  {
    "div_id": "top_div",
    "element_id": "Topdiv"
  },
  {
    "div_id": "bottom_div",
    "element_id": "Bottomdiv"
  },
  {
    "div_id": "warranty_div",
    "element_id": "Warranty"
  },
  {
    "div_id": "poster_div",
    "element_id": "Poster"
  },
  {
    "div_id": "faqs_div",
    "element_id": "Faqs"
  },
  {
    "div_id": "reviews_div",
    "element_id": "Reviews"
  },
  {
    "div_id": "footer_div",
    "element_id": "FooterR"
  }
];



window.renderThis = (category: string, custom_blocks?: any, pageData?: Object) => {
  console.log("window.renderThis stopped");
  return;

  ProductPageRenderStart(render_category, custom_blocks, pageData || {});
};





if (process.env.NODE_ENV === 'development') {
  const rootElement = document.getElementById("root") as HTMLElement;
  console.log("---------- rootElement ------------ ", rootElement)

  //const root = ReactDOM.createRoot(document.getElementById(div_id) as HTMLElement);
//  const root = ReactDOM.createRoot(rootElement);

  static_blocks.forEach(block => {
    const innerDiv = document.createElement('div');
    innerDiv.id = block.div_id;
    rootElement.appendChild(innerDiv);
  });
  ProductPageRenderStart(render_category, [], {});


}
