/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { PlasmicRootProvider, PlasmicComponent } from '@plasmicapp/loader-react';
import { PLASMIC } from './plasmic-init';
import { createPortal } from 'react-dom';
import { createRoot } from 'react-dom/client';


interface PageProps {
  page_data?: {
    [key: string]: any;
  };
  [key: string]: any;
}

async function fetchProductData() {
  console.log("fbd_g");
  let currentUrl = new URL(window.location.href);
  let origin = currentUrl.hostname.replace(/^(https?:\/\/)?(www\.)?/, '');
  const pathParts = currentUrl.pathname.split('/').filter(Boolean);
  let handle = pathParts[pathParts.length - 1];

  if (process.env.NODE_ENV === 'development') {
    handle = 'haireraser';
    origin = 'nectarra.com';
  }
  
  const apiUrl = `https://mighty-hq.directus.app/flows/trigger/7dbb3760-66ac-4f72-bf5e-9742af212f73?handle=${handle}&origin=${origin}&category=product`;
  //console.log("apiUrl: ", apiUrl);
  const response = await fetch(apiUrl);
  const data = await response.json();
  return data;
}

function trigger_hybrid(PageProps: PageProps) {
  console.log("trg_e");

 
 
  const event = new CustomEvent('customPageSatellite', {
      detail: {
          page_param: PageProps.handle,
          productData: PageProps,
          experiment_active: PageProps.store_page?.experiment_active || false,
          page_id: PageProps.store_page?.id || "",
          ev: PageProps.store_page?.ev || ""
      }
  });

  // Dispatch the event
  document.dispatchEvent(event);
  console.log("trg_e_d");
}



let blocks = [
  { divId: 'top_div', elementId: 'Topdiv', isLazy: false },
  { divId: 'bottom_div', elementId: 'Bottomdiv', isLazy: false },
  { divId: 'footer_div', elementId: 'FooterR', isLazy: false },
  { divId: 'faqs_div', elementId: 'Faqs', isLazy: true },
  { divId: 'warranty_div', elementId: 'Warranty', isLazy: true },

  { divId: 'reviews_div', elementId: 'Reviews', isLazy: true },
  { divId: 'poster_div', elementId: 'Poster', isLazy: true },

];

const Block = ({ divId, elementId, isLazy, props }: { divId: string, elementId: string, isLazy: boolean, props: any }) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const targetElement = document.getElementById(divId);

  if (!targetElement) {
    return null;
  }

  if (isLazy && !isClient) {
    return createPortal(<div>Loading...</div>, targetElement);
  }

  const Component = () => (
    <PlasmicComponent component={elementId} componentProps={{ divobj: props }} />
  );

  return createPortal(
    <Suspense fallback={<div>Loading...</div>}>
      <Component />
    </Suspense>,
    targetElement
  );
};

function App({ props }: { props: any }) {
  return (
    <PlasmicRootProvider loader={PLASMIC} prefetchedQueryData={props}>
      {blocks.map(({ divId, elementId, isLazy }) => (
        <Block key={divId} divId={divId} elementId={elementId} isLazy={isLazy} props={props} />
      ))}
    </PlasmicRootProvider>
  );
}


async function ProductPageRenderStart(category: string, custom_blocks: any, pageData: PageProps) {
  console.log("---------- prs ------------ ", pageData);

  let props: PageProps = {};


  if (category === "custom" && Array.isArray(custom_blocks) && custom_blocks.length > 0) {
    if (custom_blocks.every(block => typeof block === 'object' && block !== null)) {
      blocks = custom_blocks;
    } else {
      console.error("Invalid custom_blocks: Not all elements are objects");
    }
  } 

  if (category === "products") {
    if (typeof pageData === 'object' && pageData !== null && Object.keys(pageData).length > 0) {
      // console.log("pageData provided: ", pageData);
       props = pageData;
     } else {
       console.log("NO pageData");
       //return;
       props = await fetchProductData();
     }
  let check_data = props;
  let d_data = check_data.page_data;
  if (typeof d_data === 'object' && d_data !== null && Object.keys(d_data).length > 0) {
    // console.log("pageData provided: ", pageData);
   } else {
    blocks = [
      { divId: 'footer_div', elementId: 'FooterR', isLazy: false },
      { divId: 'reviews_div', elementId: 'Reviews', isLazy: true },
    ];
   }
  } else {

    blocks = [
      { divId: 'footer_div', elementId: 'FooterR', isLazy: false },
    ];

  }
    blocks.forEach(({ divId, elementId, isLazy }) => {
    const targetElement = document.getElementById(divId);
    if (targetElement) {
      const root = createRoot(targetElement);
      root.render(
        <React.StrictMode>
          <PlasmicRootProvider loader={PLASMIC} prefetchedQueryData={props}>
            <Block divId={divId} elementId={elementId} isLazy={isLazy} props={props} />
          </PlasmicRootProvider>
        </React.StrictMode>
      );
    }
  });

  if (category === "products") {
    trigger_hybrid(props);
  }

}

export default ProductPageRenderStart;

/* eslint-enable @typescript-eslint/no-unused-vars */   