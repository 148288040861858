import { initPlasmicLoader } from "@plasmicapp/loader-react";

const all_projects = [
  {
    name: "nectarra",
    domains: ["nectarra.com", "nectarra.com/en"],
    id: "2reBP21Sus2fC1MTsFy9Sy",  // ID of a project you are using
    token: "nfd7zxGVEFsUvGRgdo6zaxktugQ3NPelEWn2ilKq7FKbb5Cpi1DT2cYULijTfDKyt5xKJkJjpp6TFRN77w"  // API token for that project
  },
  {
    name: "birdie",
    domains: ["birdieorbetter.com"],
    id: "o2dni44H8ZYZKX23ueXYYu",  // ID of a project you are using
    token: "3y7lVJJpdUfX1oCeHmzasdSRJxuuDvjWHHiPYYWpcLNgfBGzINB740xgyCDo9mVvs69MVBHPptKZHFPJUucw"  // API token for that project
  },
  {
    name: "omnicup",
    domains: ["omnicuptherapy.com"],
    id: "vrG7sHuZLQduMcMQJFMVdZ",  // ID of a project you are using
    token: "S2CHsoqws1Eyx7fWvUZ9BnvEM4j2zvvf6TKP26DjywrvRnuL3e7QAf9DDGelH6ZWslRuNCp9Ehu8uRS2szw"  // API token for that project
  },
  {
    name: "scorched",
    domains: ["scorchedgoods.com", "scorchedlife.com", "scorchedsurvival.com", "scorchedsport.com"],
    id: "rwmrjm3AcR2TJ5AS8VqcMD",  // ID of a project you are using
    token: "WbSqFvJsvKvgkDzrbnysQqb8L2rxPaZNArASbnpf1i1bDwJbFcIJuaaUpwvfWci3hP8n7rwZ9WHfh0wmTg"  // API token for that project
  },
  {
    name: "cuddlecloudwear",
    domains: ["cuddlecloudwear.com"],
    id: "u5vDeHmc6cjxK5X4nnghPW",  // ID of a project you are using
    token: "FkgA8aRcZOTD9vtKtleOlFDBZSIOQ2cew84Ibr4wAQrsgE5TdNH6jrfOsUOfZGHaLfup2fDgFwU8ZHNnVXDKw"  // API token for that project
  }
];

let project_id: string;
let project_token: string;
let project_url: string;
if (process.env.NODE_ENV === 'development' || window.location.hostname === 'localhost') {
  project_id = "2reBP21Sus2fC1MTsFy9Sy";
  project_token = "nfd7zxGVEFsUvGRgdo6zaxktugQ3NPelEWn2ilKq7FKbb5Cpi1DT2cYULijTfDKyt5xKJkJjpp6TFRN77w";
  project_url = "devENV";
} else {
  const wh = window.location.hostname;
  let domain = wh.replace(/^(?:https?:\/\/)?(?:www\.)?([^\/]+).*/, '$1');

  project_id = all_projects.find(p => p.domains.includes(domain))?.id || '';
  project_token = all_projects.find(p => p.domains.includes(domain))?.token || '';
  project_url = all_projects.find(p => p.domains.includes(domain))?.name || '';
}
console.log("project_url", project_url);
export const PLASMIC = initPlasmicLoader({
  projects: [
    {
      id: project_id,
      token: project_token,
    }
  ],
  // Fetches the latest revisions, whether or not they were unpublished!
  // Disable for production to ensure you render only published changes.
  preview: false,
})